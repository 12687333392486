<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import dayjs from "dayjs";
import api from "@/http";
import useEventsBus from "@/utils/eventbus";
import useVuelidate from "@vuelidate/core";
import {
  required,
  helpers,
  url,
  minLength,
  maxLength,
} from "@vuelidate/validators";

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const now = dayjs();
const { emitt } = useEventsBus();

const sixMonthsAhead = ref(now.add(180, "day").format("YYYY-MM-DD"));
const minDate = computed(() => dayjs().add(-1, "hour").format("YYYY-MM-DD"));
const regexVal = helpers.regex(/^[a-zA-Z0-9-]+$/);

const noBlackListUrl = helpers.withMessage(
  "cannot use this url",
  (value: string) => {
    const blackListRegex = /^(http|https):\/\/(www\.)?ptpn\.id.*$/;
    return !blackListRegex.test(value);
  }
);

const ajaxOnProcess = ref(false);
const defaultInput = ref();
const source = ref("");

const button = ref();

const form = reactive({
  url: null,
  alias: null,
  expiry: null,
  captcha_token: null,
});

const mustAhead = (value: any) => {
  if (!form.expiry) return true;
  const a = dayjs();
  const b = a.add(60, "minute");

  return dayjs(value).isAfter(b, "minute");
};

const vuelidateRules = computed(() => ({
  url: {
    required: helpers.withMessage("url must be filled", required),
    mustValidUrl: helpers.withMessage("url format must be valid", url),
    noBlackListUrl,
    $lazy: true,
  },
  alias: {
    minLength: helpers.withMessage("minimum 5 characters", minLength(5)),
    maxLength: helpers.withMessage("maximum 50 characters", maxLength(50)),
    regexVal: helpers.withMessage("must be alphanum and hyphen", regexVal),
    $lazy: true,
  },
  expiry: {
    mustAhead: helpers.withMessage("at least one hour", mustAhead),
  },
}));

const v$ = useVuelidate(vuelidateRules, form);

const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${year}-${month}-${day} ${hour}:${minute}:00`;
};
const recaptcha = async () => {
  await recaptchaLoaded();
  return await executeRecaptcha("submit");
};

const resetForm = () => {
  form.url = null;
  form.alias = null;
  form.expiry = null!;
  form.captcha_token = null;
  v$.value.$reset();
};
const copyToClipboard = async (valueToCopy: string) => {
  if (location.protocol !== "https:") {
    return emitt("showToast", {
      type: "warning",
      message: "text.this feature is only available on secure connections",
      duration: 3,
      position: "top-center",
    });
  }

  try {
    if (!valueToCopy || valueToCopy === "") return;
    await navigator.clipboard.writeText(valueToCopy);
    return emitt("showToast", {
      type: "success",
      message: "text.url successfully copied to clipboard",
      duration: 3,
      position: "top-center",
    });
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

const submitForm = async () => {
  if (ajaxOnProcess.value) return;

  const result = await v$.value.$validate();
  if (!result) return defaultInput.value?.focus();

  let newForm = new FormData();

  try {
    form.captcha_token = await recaptcha();

    newForm.append("url", form.url ? form.url : "");
    newForm.append("alias", form.alias ? form.alias : "");
    newForm.append(
      "expiry",
      form.expiry ? dayjs(form.expiry).format("YYYY-MM-DD HH:mm:ss WIB") : ""
    );
    newForm.append(
      "captcha_token",
      form.captcha_token ? form.captcha_token : ""
    );

    ajaxOnProcess.value = true;
    const response = await api.post(`shorten`, newForm);
    if (response && response.status === 200) {
      source.value = response.data.alias;
      setTimeout(() => {
        resetForm();
      }, 1500);
    }
  } catch (error: any) {
    source.value = "";
    if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
      emitt("showMessageBox", {
        message: "error.server is taking too long to respond",
        title: "error.submit failed",
        mode: "error",
        okButtonTitle: "button.ok",
        mustTranslate: true,
      });
    } else {
      emitt("showMessageBox", {
        message: "error." + error.response.data.message,
        title: "error.submit failed",
        mode: "error",
        okButtonTitle: "button.ok",
        mustTranslate: true,
      });
    }

    // ajaxOnProcess.value = false;
  } finally {
    setTimeout(() => {
      ajaxOnProcess.value = false;
    }, 1500);
  }
};
</script>
<template>
  <div>
    <Transition
      name="fade"
      mode="out-in"
      :duration="{ enter: 500, leave: 500 }"
    >
      <div
        v-if="!ajaxOnProcess && source !== ''"
        class="bg-gray-800 bg-opacity-70 border border-teal-400 rounded-lg p-3 flex justify-between items-center mb-3 space-x-2"
      >
        <p class="text-gray-300">
          {{ $t("text.your short url") }} :
          <a
            v-tippy="{
              content: $t('tooltip.open the link in a new tab'),
              animation: 'perspective',
              placement: 'top',
            }"
            class="font-extrabold text-gray-200 hover:text-amber-200"
            :href="source"
            target="_blank"
            >{{ source }}</a
          >
        </p>
        <button
          @click="copyToClipboard(source)"
          v-tippy="{
            content: $t('tooltip.copy to clipboard'),
            animation: 'perspective',
            placement: 'top',
          }"
          ref="button"
          class="bg-teal-500 hover:bg-teal-700 text-gray-100 font-bold p-2 rounded"
        >
          <mdicon name="clipboard-check-outline" class="h-3 w-3" />
        </button>
      </div>
    </Transition>
    <form @submit.prevent="submitForm" class="text-left w-full">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
        <div class="col-span-6">
          <label for="email" class="block mb-2 font-medium text-gray-100"
            >{{ $t("label.paste a long url") }}&nbsp;:</label
          >
          <input
            ref="defaultInput"
            type="text"
            id="url"
            name="url"
            v-model="form.url"
            class="bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-400 rounded-lg block w-full p-2.5"
            :class="{
              'border-red-400 border-2 focus:ring-red-500 focus:border-red-500 ':
                v$.url.$errors.length,
              'border-teal-400 border-2 focus:ring-teal-500 focus:border-teal-500 ':
                !v$.url.$error && v$.url.$dirty,
            }"
            :placeholder="$t('placeholder.example long url')"
          />
          <p
            class="pl-2 text-red-400 text-sm font-semibold"
            v-for="$error in v$.url.$errors"
            :key="$error.$property"
          >
            {{ $t("error." + $error.$message) }}
          </p>
        </div>
        <div class="col-span-6 lg:col-span-4">
          <label for="password" class="block mb-2 font-medium text-gray-100"
            >{{ $t("label.special alias") }}&nbsp;:</label
          >
          <input
            type="text"
            name="alias"
            id="alias"
            v-model="form.alias"
            class="bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
            :class="{
              'border-red-400 border-2 focus:ring-red-500 focus:border-red-500 ':
                v$.alias.$errors.length,
              'border-teal-400 border-2 focus:ring-teal-500 focus:border-teal-500 ':
                !v$.alias.$error && v$.alias.$dirty,
            }"
            :placeholder="$t('placeholder.example alias')"
            aria-describedby="alias_help"
          />
          <p
            class="pl-2 text-red-400 text-sm font-semibold"
            v-for="$error in v$.alias.$errors"
            :key="$error.$property"
          >
            {{ $t("error." + $error.$message) }}
          </p>

          <div class="mt-1 text-xs text-gray-100" id="alias_help">
            {{ $t("text.if you fill in the alias the final url will be")
            }}<span class="font-semibold text-yellow-200"
              >https://ptpn.id/mantap-kali</span
            >
          </div>
        </div>
        <div class="col-span-6 lg:col-span-2">
          <label for="password" class="block mb-2 font-medium text-gray-100"
            >{{ $t("label.expired time") }}&nbsp;:</label
          >
          <VueDatePicker
            v-model="form.expiry"
            disable-year-select
            :min-date="minDate"
            auto-apply
            :max-date="sixMonthsAhead"
            prevent-min-max-navigation
            :format="formatDate"
            :class="{
              'cust__datepicker error': v$.expiry.$errors.length,
              'cust__datepicker success':
                !v$.expiry.$error && v$.expiry.$dirty && form.expiry,
            }"
          ></VueDatePicker>
          <p
            class="pl-2 text-red-400 text-sm font-semibold"
            v-for="$error in v$.expiry.$errors"
            :key="$error.$property"
          >
            {{ $t("error." + $error.$message) }}
          </p>
        </div>
      </div>
      <div class="mt-5 block">
        <button
          v-if="!ajaxOnProcess"
          type="submit"
          class="block text-gray-50 bg-teal-600 hover:bg-teal-700 focus:ring-1 focus:outline-none focus:ring-teal-500 font-medium rounded-lg w-full md:w-48 px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
        >
          {{ $t("button.process") }}
        </button>
        <div
          v-else
          class="cursor-default flex justify-center items-center text-white bg-gray-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg h-11 w-full md:w-48 px-5 py-2.5 text-center"
        >
          <svg
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>{{ $t("button.processing") }}...</span>
        </div>
      </div>
    </form>
  </div>
</template>
<style lang="scss">
.dp__input_wrap {
  @apply h-11.5;
}
.dp__input {
  @apply h-full rounded-lg;
}
.mdi-clipboard-check-outline svg {
  @apply w-7 h-7;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.cust__datepicker.error {
  .dp__input_wrap input {
    @apply border-red-400 border-2 focus:ring-red-500 focus:border-red-500;
  }
}
.cust__datepicker.success {
  .dp__input_wrap input {
    @apply border-teal-400 border-2 focus:ring-teal-500 focus:border-teal-500;
  }
}
</style>
